<template>
  <div class="flex flex-row w-full items-center py-1 pe-1 ps-3 min-w-14 bg-white font-medium border-l border-gray-200">
    {{typeof exp === 'string' ? `"${exp}"` : exp }} <a @click="val" class="ms-4 cursor-pointer">Val</a> <a @click="func" class="ms-4 cursor-pointer">Fnc</a>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useAppeggio } from '../../app/useAppeggio.js'

const a = useAppeggio()

const p = defineProps({ p: { type: Object }, cl: { type: String } })
const emit = defineEmits([ 'changed' ])

const exp = computed(_ => p.p?.exp || null)

const val = $e => {
  emit('changed', { idx: p.p.idx, exp: 'Test' })
}

const func = $e => {
  emit('changed', { idx: p.p.idx, exp: a.li(`'(+ 40 2)`) })
}
</script>
